var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('div',{staticClass:"projects-wrapper col-12 mt-0 mt-sm-16"},[_c('v-chip-group',{staticClass:"projects-chip-group",attrs:{"column":"","mandatory":"","active-class":"primary"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}},_vm._l((_vm.categories),function(category){return _c('v-chip',{key:category,staticClass:"ma-2",attrs:{"filter":"","filter-icon":"mdi-arrow-down-left"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.categoryFilter(category)}}},[_c('router-link',{staticClass:"nav-link text-decoration-none black--text",attrs:{"to":{
              name: 'projects',
              query: { category: encodeURIComponent(category) },
            }}},[_vm._v(" "+_vm._s(category)+" ")])],1)}),1),_c('v-row',{staticClass:"ma-0"},_vm._l((_vm.filteredProjects),function(project){return _c('v-col',{key:project.title.replace(/\s/g, ''),staticClass:"d-flex child-flex",attrs:{"cols":"12","sm":"6","xl":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-img',{staticClass:"secondary",attrs:{"src":project.image,"lazy-src":project.thumbnail,"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black"}})],1)]},proxy:true}],null,true)},[_c('v-expand-transition',[(hover)?_c('router-link',{staticClass:"\n                    d-flex\n                    flex-column\n                    transition-fast-in-fast-out\n                    v-card--reveal\n                    text-decoration-none\n                  ",staticStyle:{"height":"100%"},attrs:{"to":{
                    name: 'project',
                    params: { id: encodeURIComponent(project.title) },
                  }}},[_c('div',{staticClass:"project-reveal-spacer flex-grow-1"}),_c('div',{staticClass:"\n                      project-reveal-content-wrapper\n                      d-flex\n                      justify-space-between\n                    "},[_c('div',{staticClass:"project-reveal-text-wrapper"},[_c('div',{staticClass:"project-reveal-category pb-xl-2"},_vm._l((project.categories),function(category,index){return _c('span',{key:category.replace(/\s/g, '') + index,staticClass:"white--text pr-2"},[_vm._v(" # "+_vm._s(category))])}),0),_c('div',{staticClass:"project-reveal-title"},[_c('h2',{staticClass:"white--text mb-8"},[_vm._v(_vm._s(project.title))])])]),_c('div',{staticClass:"\n                        project-reveal-icon-wrapper\n                        white--text\n                        text-right\n                      "},[_c('v-icon',{staticClass:"icon-nav-arrow",attrs:{"large":"","color":"white"}},[_vm._v("mdi-arrow-top-right")])],1)])]):_vm._e()],1)],1)]}}],null,true)})],1)}),1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }